<template>
  <div>
    <a-table
      :dataSource="table_data"
      :columns="table_columns"
      :pagination="pagination_props"
      @change="changeCurrentPage"
      :loading="loading_data"
    >
      <template #idRender="{ text }">
        <span>#{{ text }}</span>
      </template>

      <template #filterDropdown="{ column, confirm, clearFilters }">
        <div style="padding: 8px">
          <a-input
            v-model:value="filter_values[column.key]"
            :maxlength="30"
            :placeholder="`Buscar por ${column.title}`"
            @pressEnter="
              handleSearch(column.dataIndex, filter_values[column.key], confirm)
            "
            style="width: 188px; margin-bottom: 8px; display: block"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="
              handleSearch(column.dataIndex, filter_values[column.key], confirm)
            "
          >
            <template #icon><search-outlined /></template>
            Buscar
          </a-button>

          <a-button
            size="small"
            style="width: 90px"
            @click="handleColumnReset(column.dataIndex, clearFilters)"
          >
            Limpiar
          </a-button>
        </div>
      </template>

      <template #multipleRender="{ text: values }">
        <span
          >{{ values[0] }}
          <b v-if="values.length > 1">+{{ values.length - 1 }}</b></span
        >
      </template>

      <template #actionDetails="{ text: id }">
        <a-tooltip color="#05045D">
          <template #title>Ver más</template>
          <router-link
            :to="{ name: 'DetalleCobranzaFacturacion', params: { id } }"
          >
            <eye-outlined />
          </router-link>
        </a-tooltip>
      </template>

      <template #stateRender="{ text: state }">
        <a-tag :color="getTagColor(state)">
          {{ getStateName(state) }}
        </a-tag>
      </template>

      <template #filterCompanies="{ column, confirm, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-select
            v-model:value="selected_companies"
            :options="company_list"
            mode="multiple"
            show-search
            :filterOption="true"
            optionFilterProp="label"
            placeholder="Buscar por Razon social"
            style="width: 100%"
            size="middle"
          >
            <template #dropdownRender="{ menuNode: menu }">
              <v-nodes :vnodes="menu" />
              <div
                style="
                  border-top: 1px solid #f0f0f0;
                  display: flex;
                  justify-content: space-between;
                "
                class="p-2"
                @mousedown="(e) => e.preventDefault()"
              >
                <a-button
                  type="primary"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="
                    handleSearch(
                      column.dataIndex,
                      selected_companies.join(','),
                      confirm
                    )
                  "
                >
                  Buscar
                </a-button>

                <a-button
                  size="small"
                  style="width: 90px"
                  @click="handleColumnReset(column.dataIndex, clearFilters)"
                >
                  Limpiar
                </a-button>
              </div>
            </template>
          </a-select>
          <div
            class="p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  selected_companies.join(','),
                  confirm
                )
              "
            >
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <!-- Filtros para estados -->

      <template #filterStates="{ confirm, column, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-checkbox-group v-model:value="states_selection">
            <a-row>
              <a-col
                :span="24"
                v-for="(state, index) in states_options"
                :key="index"
              >
                <a-checkbox :value="state.value">{{ state.label }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>

          <div
            class="mt-4 p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  states_selection.join(','),
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <!-- Icono de filtrado para seleccion por checkbox -->
      <template #filterIconState="{ column }">
        <filter-outlined
          :style="{
            color:
              search_value[column.dataIndex] === undefined ||
              search_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>

      <template #periodRender="{ text: period }">
        <span>{{ addLeadingZerosToPeriod(period) }}</span>
      </template>

      <template #filterPeriod="{ column, confirm, clearFilters }">
        <div style="padding: 8px">
          <a-month-picker
            v-model:value="filter_values[column.key]"
            placeholder="Seleccionar periodo"
          />

          <div
            class="p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  filter_values[column.key],
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <template #filterIcon="{ column }">
        <search-outlined
          :style="{
            color:
              search_value[column.dataIndex] === undefined ||
              search_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>
    </a-table>
  </div>
</template>

<script>
import moment from 'moment';
import { computed, onMounted, reactive, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import {
  EyeOutlined,
  SearchOutlined,
  FilterOutlined
} from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import {
  GET_CURRENT_PAGE_TABLE,
  GET_ITEMS_PER_PAGE_TABLE,
  GET_TOTAL_PAGES_TABLE,
  GET_COLLECTION_FORMS,
  FETCH_COLLECTION_FORMS,
  GET_ALL_COMPANIES,
  FETCH_ALL_COMPANIES
} from '../../store/types';
import getTagColor from '../../utils/getTagColor';
import getTagText from '../../utils/getTagText';
import parseDate from '@/utils/dateFormatter';
import notification from '@/utils/notifications';

export default {
  name: 'CollectionBillingTickets',
  components: {
    'eye-outlined': EyeOutlined,
    'search-outlined': SearchOutlined,
    'filter-outlined': FilterOutlined,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    }
  },
  setup() {
    // * Vuex
    const store = useStore();

    // * routers
    const route = useRoute();
    const router = useRouter();

    // * Tabla
    // total de items por tabla
    const total = computed(() => {
      return store.getters[GET_TOTAL_PAGES_TABLE];
    });
    // items por pagina
    const items_per_page = computed(() => {
      return store.getters[GET_ITEMS_PER_PAGE_TABLE];
    });
    // Pagina en la que nos encontramos
    const current_page = computed(() => {
      return store.getters[GET_CURRENT_PAGE_TABLE];
    });

    const pagination_props = reactive({
      total,
      pageSize: items_per_page,
      current: current_page
    });

    const table_data = computed(() => {
      return store.getters[GET_COLLECTION_FORMS].map((obj) => {
        return {
          key: obj.id,
          id: obj.id,
          state: obj.state,
          holdingName: obj.holding.name != undefined ? obj.holding.name : '-',
          subHoldingName:
            obj.subHoldings.length == 0
              ? '-'
              : obj.subHoldings.map((obj) => obj.name),
          companyId:
            obj.companies.length == 0
              ? '-'
              : obj.companies.map((obj) => obj.name),
          numberPolicy: obj.policies.join(' - '),
          numberTickets: obj.ticketsCount,
          period: obj.period,
          action: obj.id
        };
      });
    });

    const table_columns = [
      {
        title: 'Id (s)',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'idRender'
        }
      },
      {
        title: 'Estado',
        dataIndex: 'state',
        key: 'state',
        slots: {
          customRender: 'stateRender',
          filterDropdown: 'filterStates',
          filterIcon: 'filterIconState'
        }
      },
      {
        title: 'Holding',
        dataIndex: 'holdingName',
        key: 'holdingName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Subholding',
        dataIndex: 'subHoldingName',
        key: 'subHoldingName',
        slots: {
          customRender: 'multipleRender',
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Razón social',
        dataIndex: 'companyId',
        key: 'companyId',
        slots: {
          customRender: 'multipleRender',
          filterDropdown: 'filterCompanies',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Póliza',
        dataIndex: 'numberPolicy',
        key: 'numberPolicy',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'N° tickets',
        dataIndex: 'numberTickets',
        key: 'numberTickets'
      },
      {
        title: 'Periodo',
        dataIndex: 'period',
        key: 'period',
        slots: {
          filterDropdown: 'filterPeriod',
          filterIcon: 'filterIcon',
          customRender: 'periodRender'
        }
      },
      {
        title: 'Accion',
        dataIndex: 'action',
        key: 'action',
        slots: {
          customRender: 'actionDetails'
        }
      }
    ];

    const search_value = ref({}); // Objeto que guarda los valores de los cuales se hace la busqueda por filtro
    const filter_values = ref({}); // Objeto que guarda los valores de los filtros

    // Busqueda por columna
    const handleSearch = (column, value, confirm) => {
      if (value != '') {
        let data = value;

        // Valido el caso de que sea por el formato de fecha
        if (column === 'period') {
          const new_date = parseDate(value).substring(0, 10).split('/');
          const parsed_date = `${new_date[2]}-${parseInt(new_date[1])}`;
          data = addLeadingZerosToPeriod(parsed_date);
        }

        // Hago la redireccion con el nuevo valor
        filterRedirectionNewParam(column, data);

        // Hago que se cierre el mini modal de busqueda
        confirm();
      }
    };

    // Render de periodo
    const addLeadingZerosToPeriod = (period) => {
      const [year, month] = period.split('-');
      return `${year}-${month.padStart(2, '0')}`;
    };

    // Redireccion reseteando o sacando un parametro
    const filterRedirectionResetColumn = (key) => {
      delete search_value.value[key];
      delete filter_values.value[key];

      router.replace({
        name: 'CobranzaFacturacion',
        query: search_value.value,
        hash: '#billing'
      });
    };

    // Limpiar columna
    const handleColumnReset = (column, resetConfirm) => {
      filterRedirectionResetColumn(column);
      resetConfirm();
      delete filter_values.value[column];

      if (column === 'state') {
        states_selection.value = [];
      }

      if (column === 'companyId') {
        selected_companies.value = [];
      }
    };

    // * Lista de compañias
    const selected_companies = ref([]);
    const company_list = computed(() => {
      return store.getters[GET_ALL_COMPANIES].map((obj) => {
        return {
          value: obj.id,
          label: obj.businessName
        };
      });
    });
    const getAllCompanies = () => {
      try {
        store.dispatch(FETCH_ALL_COMPANIES);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    // Seleccion de estados
    const states_selection = ref([]);
    const states_options = ref([
      {
        label: 'Creado',
        value: 'CREATED'
      },
      {
        label: 'En proceso',
        value: 'PROCCESING'
      },
      {
        label: 'Cargado',
        value: 'LOADED'
      },
      {
        label: 'Cancelado',
        value: 'CANCELLED'
      }
    ]);

    const getStateName = (name) => {
      const state_names = {
        CREATED: 'Creado',
        PROCCESING: 'En proceso',
        LOADED: 'Cargado',
        CANCELLED: 'Cancelado'
      };

      return state_names[name];
    };

    const filterRedirectionNewParam = (key, param) => {
      search_value.value[key] = param;

      if (key != 'page') {
        search_value.value['page'] = 1;
      }

      router.replace({
        name: 'CobranzaFacturacion',
        query: search_value.value,
        hash: '#billing'
      });
    };

    const changeCurrentPage = (page) => {
      filterRedirectionNewParam('page', page.current);
    };

    // * Cuando cambie la url o cargue la pagina, voy  a llamar al endpoint que me obtiene la data para la tabla
    const loading_data = ref(false); // efecto de carga de la tabla cuando encuentre la data
    const getCOllectionBillingForms = async (params) => {
      loading_data.value = true;
      try {
        await store.dispatch(FETCH_COLLECTION_FORMS, params);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading_data.value = false;
    };

    watchEffect(async () => {
      if (route.name === 'CobranzaFacturacion') {
        const params = route.query;
        if ('page' in params === false) {
          params['page'] = 1;
        }
        const string_param = new URLSearchParams(params).toString();
        getCOllectionBillingForms(string_param);
      }
    });

    // * Recarga de pagina, obtencion de los valores de la url a filtros ### (4) ###
    onMounted(() => {
      // Al momento de cargar la pagina, voy a ver que parametros vienen, con eso voy a dejar los valores de cada uno de los filtros realizados ahi
      const keys = Object.keys(route.query);
      keys.forEach((element) => {
        search_value.value[element] = route.query[element]; // Le paso valor al objeto de la busqueda en caso de hacer otra consulta
        // en caso de que el filtro que venga sea 'State', voy a dejar selecciadas las casillas checkbox
        if (element === 'state') {
          states_selection.value.push(...route.query[element].split(','));
          // states_selection.value.push(...route.query[element].split(',').map((e) => parseInt(e)));
        }

        if (element === 'companyId') {
          filter_values.value[element] = [...route.query[element].split(',')];
          selected_companies.value.push(
            ...route.query[element].split(',').map((e) => parseInt(e))
          );
        }

        if (element === 'period') {
          filter_values.value[element] = moment(
            route.query[element],
            'YYYY-MM'
          );
        } else {
          filter_values.value[element] = route.query[element]; // Le paso valor al los inputs para que muestre que es lo que busco anteriormente
        }
      });

      getAllCompanies();
    });

    return {
      pagination_props,
      table_data,
      table_columns,
      loading_data,
      changeCurrentPage,
      states_selection,
      states_options,
      handleColumnReset,
      handleSearch,
      search_value,
      filter_values,
      getStateName,
      getTagColor,
      getTagText,
      addLeadingZerosToPeriod,
      selected_companies,
      company_list
    };
  }
};
</script>
